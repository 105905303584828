.spartan-navbar{
    width: 100vw;
    height: 100px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(3, 72, 104, .75);

    .logo-container {
        position: relative;
        height: 100px;
        width: 300px;
        padding-top: 20px;
        display: inline-block;
    }
    .menu-option-container {
        display: inline-block;
        position: relative;
        vertical-align: center;
        width: calc(100% - 300px);
        float: right;

        .menu-option {
            position: relative;
            color: #fff;
            text-align: center;
            transform: translate(1s);
            height: 100px;
            width: 200px;
            float: right;
            background-color: rgba(0,0,0,0.2);
            display: inline-block;
            cursor: pointer;
            strong {
                position: absolute;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); 
            }
            &:hover {
                background-color: rgba(0,0,0,0.5);
            }
        }
    }
    
}