body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spartan-main-container {
  background: #034868;
  background-image: url(https://spartanradarco.wpenginepowered.com/wp-content/themes/spartan-radar/assets/images/BG_Pattern.png);
  background-image: url(https://spartanradarco.wpenginepowered.com/wp-content/themes/spartan-radar/assets/images/BG_Pattern.png), linear-gradient(to bottom, #283b44, #034868);
  background-repeat: repeat;
  background-size: 350px, cover;	
  padding-top: 150px;
  width: 100vw;
  min-height: 100vh;
}
